import React, { createContext, useContext, useReducer } from 'react';

export const StateContext = createContext({});

const fetchData = async () => {
  try {
    let user = JSON.parse(localStorage.getItem('@userInfo'));
    return user;
  } catch (e) {
    console.log(e);
  }
};

export const StateProvider = ({ children }) => {
  // intial state
  let initialState = {
    activeId: 1,
    profile: fetchData(),
  };
  // reducer
  let reducer = (state, action) => {
    switch (action.type) {
      case 'updateActiveId':
        return {
          ...state,
          activeId: action.activeId,
        };
      default:
        return { ...initialState };
    }
  };

  return <StateContext.Provider value={useReducer(reducer, initialState)}>{children}</StateContext.Provider>;
};

export const useStateValue = () => useContext(StateContext);
