import axios from 'axios';

const axiosInstance = axios.create({
  // baseURL: `${process.env.NODE_ENV === 'production' ? `https://api.medrugapp.ir/` : `http://localhost:5333/`}`,
  baseURL: 'https://api.medrugapp.ir/',
});

axiosInstance.interceptors.request.use((config) => {
  const token = localStorage.getItem('@token');
  config.headers.Authorization = token ? `Bearer ${token}` : '';
  axios.defaults.headers.common['Content-Type'] = 'application/json';
  return config;
});

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    return Promise.reject(error.response);
  }
);

export default axiosInstance;
