import React, { memo } from 'react';
import styled from 'styled-components';

import { NavLink } from 'react-router-dom';

import Home from '../../assets/images/home.png';
import Inbox from '../../assets/images/inbox.png';
import Phone from '../../assets/images/phone.png';
import Favorite from '../../assets/images/favorite.png';

const Tab = memo(() => {
  return (
    <Row>
      <NavLink exact='/' className='item' activeClassName='active' to='/'>
        <div className='icon' style={{ backgroundImage: `url(${Home})` }}></div>
        <span className='name'>صفحه اصلی</span>
      </NavLink>
      <NavLink exact='/favorites' className='item' activeClassName='active' to='/favorites'>
        <div className='icon' style={{ backgroundImage: `url(${Favorite})` }}></div>
        <span className='name'>علاقه مندی‌ها</span>
      </NavLink>
      <NavLink exact='/about' className='item' activeClassName='active' to='/about'>
        <div className='icon' style={{ backgroundImage: `url(${Inbox})` }}></div>
        <span className='name'>درباره‌ ما</span>
      </NavLink>
      <NavLink exact='/contact' className='item' activeClassName='active' to='/contact'>
        <div className='icon' style={{ backgroundImage: `url(${Phone})` }}></div>
        <span className='name'>تماس با ما</span>
      </NavLink>
    </Row>
  );
});

const Row = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #fdcb09;
  padding: 5px 10px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  z-index: 300;
  .item {
    flex: 1;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-decoration: none;
    .icon {
      opacity: 0.5;
      width: 20px;
      height: 20px;
      background-size: 20px;
      background-position: center;
      background-repeat: no-repeat;
      margin-bottom: 8px;
    }

    .name {
      font-size: 14px;
      color: #ab8d2c;
    }
  }
  .active .icon {
    opacity: 1;
    /* filter: grayscale(0%); */
  }
  .active .name {
    color: #000;
  }
`;

export default Tab;
