import React from 'react';
import styled from 'styled-components';

// Components
import Header from '../components/common/header';
import Tab from '../components/common/tab';

const Home = () => {
  return (
    <Container>
      <Header title={'درباره‌ما'} />
      <div className='content'>
        مدراگ حاصل تعامل پزشک و داروساز ایرانی است که با بهره گیری از رفرنس های روز دنیا و فارماکوپه دارویی ایران سعی دارد به جامعه جوان علوم پزشکی جهت خدمت رسانی بهتر کمک کند.
      </div>
      <div className='content'>هدف از طراحی اپلیکیشن مدراگ افزایش دقت و‌سهولت استفاده از داروها برای اطفال می باشد</div>
      <div className='content'>
        با توجه به نیاز تمامی همکاران محترم؛ سعی شده اطلاعات جامعی از تمامی دارو ها و فرآورده های دارویی گیاهی، پره و پرو بیوتیک ها در اختیار شما کاربران قرار گیرد
      </div>
      <div className='content'>دکتر مینا حجازی (داروساز)</div>
      <div className='content'>
        ایمیل: <a href='mailto:minahejazi1374@gmail.com'>minahejazi1374@gmail.com</a>
      </div>
      <div className='content'>دکتر مسعود ممیزالاشجار (پزشک)</div>
      <div className='content'>
        ایمیل:<a href='mailto:masoudmomayez90@gmail.com'>masoudmomayez90@gmail.com</a>
      </div>
      <Tab />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  /* align-items: center; */
  justify-content: flex-start;
  flex-direction: column;
  position: relative;
  height: 100vh;
  background-color: #231e5b;
  .content {
    /* position: relative;
    overflow: auto;
    max-height: 100%;
		height: 100%; */
    display: block;
    padding: 0 20px;
    margin: 10px 0;
    z-index: 250;
    color: #fff;
    a {
      color: #0f81ce;
    }
  }
`;

export default Home;
