import _ from 'lodash';
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { motion, AnimatePresence } from 'framer-motion';

// Components
import Header from '../components/common/header';
import Modal from '../components/common/modal';
import Premium from '../components/common/premium';
import Items from '../assets/resources/data.json';
import LeftArrow from '../assets/images/left-arrow.png';
// import axios from '../utils/axios';

const getParameterByName = (name, url = window.location.href) => {
  name = name.replace(/[\[\]]/g, '\\$&');
  var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
};

const Products = ({ match }) => {
  const [selectedItem, setSelectedItem] = useState(null);
  const [modalStatus, setModalStatus] = useState(false);
  const [items, setItems] = useState([]);
  const [maximum] = useState(match.params ? (match.params.id === '5ffd99fea9019d2c74406643' ? 4 : 0) : 0);
  const [isPaid] = useState(JSON.parse(localStorage.getItem('@isPaid')));

  useEffect(() => {
    return fetchData();
  }, [match]);

  const fetchData = () => {
    // axios.get('/products?_limit=300').then((res) => console.log(JSON.stringify(res.data)));
    if (match.params.id) {
      let result = Items.filter((item) => item.categories.id === match.params.id);
      result = _.sortBy(result, ['title']);
      return setItems([...result]);
    }
    let result = _.sortBy(Items, ['title']);
    return setItems([...result]);
  };

  const onSearch = (e) => {
    let value = e.target.value;
    if (value.length > 1 && value.trim() !== '') {
      const filteredItems = items.filter((item) => item.title.toLocaleLowerCase().includes(value.toLocaleLowerCase()));
      return setItems([...filteredItems]);
    } else {
      return fetchData();
    }
  };

  const onClose = () => {
    setSelectedItem(null);
    let element = document.getElementsByClassName('app');
    return element[0].classList.remove('no-scroll');
  };

  const onClosePremiumModal = () => {
    setModalStatus(false);
    let element = document.getElementsByClassName('app');
    return element[0].classList.remove('no-scroll');
  };

  const onOpenModal = (item, index) => {
    let element = document.getElementsByClassName('app');
    element[0].classList.add('no-scroll');
    if (isPaid) {
      return setSelectedItem(item);
    }
    if (index <= maximum) {
      return setSelectedItem(item);
    }
    return setModalStatus(true);
  };

  let title = getParameterByName('title');

  return (
    <Container>
      <Header inner title={title ? title : 'فهرست دارو‌ها'} />
      <input className='search-input' type='text' placeholder='نام دارو مورد نظر' onChange={onSearch} />
      <InnerContainer>
        <AnimatePresence>
          {items.map((item, index) => {
            return (
              <motion.div key={item._id} className='items' initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} onClick={() => onOpenModal(item, index)}>
                {index > maximum && !isPaid ? <div className='lock'></div> : null}
                <span className='name'>{item.title}</span>
                <span className='description'>{item.description.slice(0, 80)}</span>
                <div className='icon' style={{ backgroundImage: `url(${LeftArrow})` }}></div>
              </motion.div>
            );
          })}
        </AnimatePresence>
      </InnerContainer>
      {selectedItem && <Modal selectedItem={selectedItem} onClose={onClose} />}
      {modalStatus && <Premium onClose={onClosePremiumModal} />}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  /* height: 100vh; */
  background-color: #231e5b;
  margin-top: 60px;
  padding-top: 10px;
  overflow: unset;
  .search-input {
    font-family: 'dana';
    margin: 5px 20px;
    min-height: 35px;
    border: 1px solid #ddd;
    border-radius: 6px;
    padding: 0 10px;
    font-size: 15px;
    color: #231e5b;
  }
`;

const InnerContainer = styled.div`
  padding: 20px;
  .items {
    position: relative;
    flex: 1 0 calc(50% - 20px);
    border-radius: 10px;
    margin-bottom: 15px;
    padding: 12px;
    border-radius: 6px;
    background-color: #fff;
    box-shadow: 0px 5px 10px 0 rgb(206 206 206 / 20%);
    overflow: hidden;
  }
  .name {
    display: block;
    font-size: 16px;
    font-weight: bold;
    color: #231e5b;
    margin-bottom: 5px;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    width: 85%;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .description {
    font-size: 14px;
    color: rgb(1 60 100 / 80%);
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    width: 85%;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .icon {
    position: absolute;
    left: 15px;
    top: 50%;
    transform: translate(0, -50%);
    width: 15px;
    height: 20px;
    background-repeat: no-repeat;
    background-size: 10px;
  }
  .lock {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 50;
  }
`;

export default Products;
