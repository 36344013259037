import React, { useState } from 'react';
import styled from 'styled-components';

// Components
import Header from '../components/common/header';
import Categories from '../components/common/categories';
import Tab from '../components/common/tab';
import Baby from '../assets/images/baby.png';
import Sneeze from '../assets/images/sneeze.png';
import Stomach from '../assets/images/stomach.png';

const items = [
  {
    _id: '5ffd99fea9019d2c74406643',
    title: 'داروها',
    child: [],
  },
  {
    _id: '5ffc75d58b2972229de26b0f',
    title: 'داروهای گیاهی',
    child: [
      {
        _id: '5ffc799c8b2972229de26b10',
        title: 'خواب آور کودکان',
        icon: Baby,
      },
      {
        _id: '5ffc75668b2972229de26b0e',
        title: 'سرفه و سرماخوردگی',
        icon: Sneeze,
      },
      {
        _id: '60054a0527a0de0ea10425cb',
        title: 'داروهای  ضد نفخ و کولیک',
        icon: Stomach,
      },
    ],
  },
  {
    _id: '5ffc79ab8b2972229de26b11',
    title: 'سایر داروها',
    child: [],
  },
];

const InnerCategory = ({ match }) => {
  let result = items.find((item) => item._id === match.params.id);

  return (
    <Container>
      <Header inner title={result ? result.title : 'دارو‌ها'} />
      <Categories defaultItems={result ? result.child : items} />
      <Tab />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  position: relative;
  height: 100vh;
  background-color: #231e5b;
  padding-top: 60px;
`;

export default InnerCategory;
