import React from 'react';
import styled from 'styled-components';

// Components
import Header from '../components/common/header';
import Tab from '../components/common/tab';

const Contact = () => {
  return (
    <Container>
      <Header title={'تماس با ما'} />
      <p className='items'>
        ایمیل پشتیبانی:
        <a href='mail:info@medrugapp.ir' target='_blank'>
          info@medrugapp.ir
        </a>
      </p>
      <Tab />
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  position: relative;
  height: 100vh;
  background-color: #231e5b;
  .items {
    position: relative;
    font-size: 18px;
    margin-bottom: 15px;
    color: #fff;
    z-index: 100;
    a {
      color: #0f81ce;
    }
  }
`;

export default Contact;
