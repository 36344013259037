import React, { Suspense, useEffect } from 'react';

import './assets/css/App.css';
import { StateProvider } from './context';

import { Switch, Route, Router, Redirect } from 'react-router-dom';

import { history } from './utils/history';

import Login from './pages/login';
import Register from './pages/register';
import Home from './pages/home';
import About from './pages/about';
import Categories from './pages/categories';
import Contact from './pages/contact';
import Products from './pages/product';
import Transaction from './pages/transactions';
import Favorites from './pages/favorites';
import Banner from './pages/banner';
import ForgotPassword from './pages/forgotPassword';
import ResetPassword from './pages/resetPassword';

const renderLoader = () => <div className='loading-container'></div>;

const showBanner = () => {
  let status = JSON.parse(localStorage.getItem('@banner')),
    result = window.matchMedia('(display-mode: standalone)').matches,
    ua = navigator.userAgent.toLowerCase(),
    isAndroid = ua.indexOf('android') > -1;
  if (isAndroid) {
    return false;
  } else {
    return !status && !result;
  }
};

const PublicRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        return !showBanner() ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/banner',
            }}
          />
        );
      }}
    />
  );
};

function MainApp() {
  useEffect(() => {
    let result = localStorage.getItem('@build'),
      currentBuild = 7;
    if (result && Number(result) !== currentBuild) {
      localStorage.setItem('@build', currentBuild);
      return window.location.reload(true);
    } else if (!result) {
      localStorage.setItem('@build', currentBuild);
      return window.location.reload(true);
    }
  }, []);

  return (
    <Suspense fallback={renderLoader()}>
      <StateProvider>
        <Router history={history}>
          <Switch>
            <PublicRoute exact path='/' component={Home} />
            <PublicRoute exact path='/categories/:id' component={Categories} />
            <PublicRoute exact path='/products' component={Products} />
            <PublicRoute exact path='/products/:id' component={Products} />
            <PublicRoute exact path='/about' component={About} />
            <PublicRoute exact path='/contact' component={Contact} />
            <PublicRoute exact path='/login' component={Login} />
            <PublicRoute exact path='/forgotPassword' component={ForgotPassword} />
            <PublicRoute exact path='/resetPassword' component={ResetPassword} />
            <PublicRoute exact path='/register' component={Register} />
            <PublicRoute exact path='/transactions/validate' component={Transaction} />
            <PublicRoute exact path='/favorites' component={Favorites} />
            <Route exact path='/banner' component={Banner} />
          </Switch>
        </Router>
      </StateProvider>
    </Suspense>
  );
}

export default MainApp;
