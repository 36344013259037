import React from 'react';
import styled from 'styled-components';

import { history } from '../../utils/history';

const Modal = ({ onClose }) => {
  const logOut = (e) => {
    onClose();
    localStorage.removeItem('@token');
    localStorage.removeItem('@userInfo');
    localStorage.setItem('@isPaid', null);
    return history.push('/login');
  };

  return (
    <OverLay onClick={onClose}>
      <Container onClick={(e) => e.stopPropagation()}>
        <span className='title'>توجه</span>
        <p className='description'>آیا مطمئن به خروج از حساب کاربری هستید ؟</p>
        <div className='row'>
          <button className='btn' onClick={logOut}>
            خروج
          </button>
          <button className='btn favorite-btn ' onClick={onClose}>
            بستن
          </button>
        </div>
      </Container>
    </OverLay>
  );
};

const Container = styled.div`
  position: absolute;
  top: 50%;
  right: 20px;
  left: 20px;
  display: flex;
  padding: 20px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  z-index: 40;
  background-color: #fff;
  transition: background-color 480ms ease-in-out;
  transform: translate(0, -50%);
  .close-btn {
    position: absolute;
    top: -5px;
    right: -5px;
    svg {
      width: 25px;
      height: 25px;
      path {
        fill: #fff;
      }
    }
  }
  .title {
    font-size: 1.4em;
    font-weight: bold;
    color: #616d85;
    margin-bottom: 15px;
    transition: color 480ms ease-in-out;
    text-align: center;
  }
  img {
    max-height: 200px;
    height: 200px;
    width: 100%;
    object-fit: cover;
    object-position: center;
    margin-left: 5px;
    border-radius: 10px;
    margin-bottom: 15px;
    background-color: #ddd;
  }
  .description {
    position: relative;
    font-size: 16px;
    color: #616d85;
    margin: 0 0 10px 0;
    transition: color 480ms ease-in-out;
    text-align: center;
    .circle {
      display: inline-block;
      vertical-align: middle;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background-color: #231e5b55;
      margin-left: 8px;
    }
  }
  .price {
    color: #222;
    font-size: 14px;
    transition: color 480ms ease-in-out;
    text-align: left;
    width: 100%auto;
    div {
      display: inline-block;
      font-size: 20px;
      font-weight: bold;
      vertical-align: middle;
    }
  }
  .row {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .btn {
    border: 1px solid transparent;
    border-radius: 4px;
    background-color: #231e5b;
    color: #fff;
    font-size: 15px;
    font-family: 'dana';
    padding: 5px 15px;
    margin-top: 20px;
    flex: 1;
  }
  .favorite-btn {
    background-color: #fff;
    border: 1px solid #231e5b;
    color: #231e5b;
    flex: 1;
    margin-right: 15px;
    height: 35px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    img {
      width: 18px;
      height: 18px;
      margin: 0;
      background-color: transparent;
      border-radius: 0;
    }
  }
  .remove-btn {
    border: 1px solid #bf0000;
  }
  .form-container {
    text-align: center;
    margin-bottom: 25px;
    .input {
      font-family: 'dana';
      margin: 5px 20px;
      min-height: 35px;
      border: 1px solid #ddd;
      border-radius: 6px;
      padding: 0 10px;
      font-size: 15px;
      color: #231e5b;
      box-shadow: unset;
      direction: ltr;
      text-align: right;
    }
  }
`;

const OverLay = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 350;
`;

export default Modal;
