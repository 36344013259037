import _ from 'lodash';
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { motion, AnimatePresence } from 'framer-motion';

// Components
import Header from '../components/common/header';
import Modal from '../components/common/modal';
import LeftArrow from '../assets/images/left-arrow.png';
import Tab from '../components/common/tab';

const Favorites = ({ match }) => {
  const [items, setItems] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);

  useEffect(() => {
    return fetchData();
  }, [match]);

  const fetchData = () => {
    let result = JSON.parse(localStorage.getItem('@favorites'));
    if (result) {
      console.log(result);
      return setItems(result);
    }
  };

  const onClose = () => {
    setSelectedItem(null);
    let element = document.getElementsByClassName('app');
    return element[0].classList.remove('no-scroll');
  };

  const onOpenModal = (item, index) => {
    let element = document.getElementsByClassName('app');
    element[0].classList.add('no-scroll');
    return setSelectedItem(item);
  };

  const onUpdate = () => {
    setSelectedItem(null);
    return fetchData();
  };

  return (
    <Container>
      <Header title={'علاقه مندی‌ها'} />
      <InnerContainer>
        <AnimatePresence>
          {items.map((item, index) => {
            return (
              <motion.div key={item._id} className='items' initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} onClick={() => onOpenModal(item, index)}>
                <span className='name'>{item.title}</span>
                <span className='description'>{item.description.slice(0, 80)}</span>
                <div className='icon' style={{ backgroundImage: `url(${LeftArrow})` }}></div>
              </motion.div>
            );
          })}
        </AnimatePresence>
        {items.length === 0 && <p className='empty-text'>هیچ آیتمی در فهرست علاقه مندی‌ها نیست.</p>}
      </InnerContainer>
      {selectedItem && <Modal withoutfavorite selectedItem={selectedItem} onClose={onClose} onUpdate={onUpdate} />}
      <Tab />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  /* height: 100vh; */
  background-color: #231e5b;
  overflow: unset;
  .empty-text {
    display: block;
    font-size: 18px;
    color: #fff;
    text-align: center;
  }
`;

const InnerContainer = styled.div`
  padding: 20px;
  padding-top: 20px;
  position: relative;
  z-index: 250;
  .items {
    position: relative;
    flex: 1 0 calc(50% - 20px);
    border-radius: 10px;
    margin-bottom: 15px;
    padding: 12px;
    border-radius: 6px;
    background-color: #fff;
    box-shadow: 0px 5px 10px 0 rgb(206 206 206 / 20%);
    overflow: hidden;
  }
  .name {
    display: block;
    font-size: 16px;
    font-weight: bold;
    color: #231e5b;
    margin-bottom: 5px;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    width: 85%;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .description {
    font-size: 14px;
    color: rgb(1 60 100 / 80%);
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    width: 85%;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .icon {
    position: absolute;
    left: 15px;
    top: 50%;
    transform: translate(0, -50%);
    width: 15px;
    height: 20px;
    background-repeat: no-repeat;
    background-size: 10px;
  }
  .lock {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.08);
    z-index: 50;
  }
`;

export default Favorites;
