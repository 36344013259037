import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import axios from '../utils/axios';
import Header from '../components/common/header';
import Close from '../assets/images/close.svg';
import Confirmation from '../assets/images/confirmation.svg';
import Tab from '../components/common/tab';

const getParameterByName = (name, url = window.location.href) => {
  name = name.replace(/[\[\]]/g, '\\$&');
  var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
};

const Transaction = () => {
  let result = JSON.parse(localStorage.getItem('@userInfo'));
  const [status, setStatus] = useState(null);
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    return fetchProfile();
  }, []);

  const fetchProfile = () => {
    return axios
      .post('/transactions/validate', { id: getParameterByName('id'), authority: getParameterByName('Authority'), packageId: getParameterByName('packageId') })
      .then((res) => {
        setLoading(false);
        const addedMonth = Number(getParameterByName('packageId')) === 1 ? 6 : 12;
        let date = new Date();
        localStorage.setItem('@isPaid', true);
        if (_.isEmpty(res.data)) {
          axios
            .put(`/users/${result.id}`, { isPaid: true, expireDate: new Date(date.setMonth(date.getMonth() + addedMonth)).toISOString() })
            .then((res) => res)
            .catch((err) => err);
          return setStatus(100);
        } else {
          return setStatus(Number(res.data.status));
        }
      })
      .catch((err) => {
        localStorage.setItem('@isPaid', false);
        setLoading(false);
      });
  };

  return (
    <Container>
      <Header title={'وضعیت تراکنش'} />
      {isLoading && (
        <div className='lds-ring'>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      )}
      {!isLoading && (
        <div className='zarinpal-status'>
          {status === 100 || status === 101 ? (
            <div className='status confirmed'>
              <span className='icon'></span>
              <p className='description'>پرداخت شما با موفقیت انجام پذیرفت.</p>
            </div>
          ) : null}
          {status !== 100 && status !== 101 ? (
            <div className='status rejected'>
              <span className='icon'></span>
              <p className='description'>پرداخت شما ناموفق بوده است.</p>
            </div>
          ) : null}
        </div>
      )}
      <Tab />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  position: relative;
  height: 100vh;
  background-color: #231e5b;
  .zarinpal-status {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding: 80px 0;
    flex-direction: column;
    text-align: center;
  }

  .zarinpal-status .icon {
    width: 70px;
    height: 70px;
    display: inline-block;
    background-size: 70px;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 15px;
    margin-bottom: 30px;
  }

  .zarinpal-status .confirmed .icon {
    background-image: url(${Confirmation});
  }

  .zarinpal-status .rejected .icon {
    background-image: url(${Close});
  }

  .zarinpal-status .description {
    font-size: 1.4em;
    margin-bottom: 15px;
    color: #fff;
  }

  .zarinpal-status .btn {
    margin-top: 30px;
    display: inline-block;
    min-width: 120px;
    font-size: 14px;
    font-weight: bold;
    color: #fff;
    background-color: #24243f;
    padding: 8px 10px;
    outline: none;
    box-shadow: none;
    transition: background-color 280ms ease, color 280ms ease;
    -webkit-transition: background-color 280ms ease, color 280ms ease;
  }

  .zarinpal-status .btn:hover {
    background-color: #303952;
    transition: background-color 280ms ease, color 280ms ease;
    -webkit-transition: background-color 280ms ease, color 280ms ease;
  }
`;

export default Transaction;
