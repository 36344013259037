import React from 'react';
import styled from 'styled-components';

import Logo from '../assets/images/logo.jpg';
import Add from '../assets/images/add.svg';
import Share from '../assets/images/share.svg';

const Banner = ({ history }) => {
  const hideBanner = () => {
    localStorage.setItem('@banner', true);
    return history.push('/');
  };

  return (
    <Container>
      <img className='logo' src={Logo} />
      <div className='text header'>افزودن اپلیکیشن</div>
      <div className='text'>با این کار همواره می‌توانید به آسانی از خدمات اپلیکیشن استفاده کنید</div>
      <div class='item'>
        <div>&#8235;۱. دکمه Share را در نوار پایین کلیک کنید</div>
        <img alt='share' src={Share} width='12px' />
      </div>
      <div class='item'>
        <div>۲. گزینه Add to Home Screen را انتخاب کنید</div>
        <img alt='plus' src={Add} width='13px' />
      </div>
      <div class='item'>
        <div>۳. در قسمت بالا روی Add کلیک کنید</div>
        <div class='blue'>Add</div>
      </div>
      <button className='btn' onClick={hideBanner}>
        متوجه شدم
      </button>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  position: relative;
  height: 100vh;
  padding: 0 30px;
  background-color: #f7f7f7;
  .logo {
    margin: 0 auto;
    margin-top: -180px;
    height: 180px;
  }
  .text,
  .item {
    display: inline-block;
    font-size: 15px;
    font-weight: 400;
    color: #231e5b;
  }
  .text {
    text-align: center;
    margin-bottom: 30px;
    color: #444;
  }
  .header {
    font-size: 18px;
    color: #000;
    text-align: center;
    margin-bottom: 20px;
  }
  .item {
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
  }
  .btn {
    position: fixed;
    bottom: 20px;
    left: 50%;
    transform: translate(-50%, 0);
    border-radius: 4px;
    border: 0;
    box-shadow: 0;
    outline: none;
    background-color: #231e5b;
    color: #fff;
    font-size: 18px;
    font-weight: bold;
    padding: 8px 25px;
    font-family: 'dana';
    margin: 0 auto;
    margin-top: 20px;
    width: calc(100% - 30px);
    display: block;
    transition: opacity 280ms ease-in;
    @media (max-width: 376px) {
      font-size: 16px;
    }
  }
`;

export default Banner;
