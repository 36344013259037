import React, { useState } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toast';

import Logo from '../assets/images/logo.jpg';
import axios from 'axios';

const getParameterByName = (name, url = window.location.href) => {
  name = name.replace(/[\[\]]/g, '\\$&');
  var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
};

const ResetPassword = ({ history }) => {
  const [form, setForm] = useState({
    password: '',
    passwordConfirmation: '',
    error: '',
  });
  const [isLoading, setLoading] = useState(false);

  const [hasFormSubmited, setFormSubmited] = useState(false);

  const validatePassword = (value) => value.length > 0;

  const submit = (e) => {
    e.preventDefault();
    setFormSubmited(true);
    if (validatePassword(form.password)) {
      setLoading(true);
      let url = `${process.env.NODE_ENV === 'production' ? `https://api.medrugapp.ir/` : `http://localhost:5333/`}`;
      // let url = `https://api.wodpolis.com/`;
      axios
        .post(`${url}auth/reset-password?code=${getParameterByName('code').trim()}`, {
          password: form.password,
          passwordConfirmation: form.passwordConfirmation,
          code: getParameterByName('code').trim(),
        })
        .then((res) => {
          toast.success('ایمیل حاوی لینک برای تغییر رمز عبور ارسال گردید.');
          return history.push('/login');
        })
        .catch((err) => {
          if (err.response && err.response.status === 400) {
            toast.error('کد وارد شده اشتباه است.');
          } else {
            toast.error('در برقراری ارتباط مشکلی به وجود آمده است، مجددا تلاش نمایید.');
          }
          setLoading(false);
        });
    }
  };

  return (
    <Container>
      <div className='logo-container'>
        <img className='logo' src={Logo} />
      </div>
      <form onSubmit={submit} noValidate>
        <div className='form-control'>
          <label>رمز عبور جدید</label>
          <input
            type='password'
            autoCapitalize={false}
            className={`form-input ${hasFormSubmited && !validatePassword(form.password) ? 'has-error' : ''}`}
            onChange={(e) => setForm({ ...form, password: e.target.value.trim() })}
          />
          {hasFormSubmited && !validatePassword(form.password) ? <span className='error'>رمز عبور جدید خود را وارد نمایید.</span> : ''}
        </div>
        <div className='form-control'>
          <label>تکرار رمز عبور جدید</label>
          <input
            type='password'
            autoCapitalize={false}
            className={`form-input ${hasFormSubmited && !validatePassword(form.passwordConfirmation) ? 'has-error' : ''}`}
            onChange={(e) => setForm({ ...form, passwordConfirmation: e.target.value.trim() })}
          />
          {hasFormSubmited && !validatePassword(form.passwordConfirmation) ? <span className='error'>تکرار رمز عبور جدید خود را وارد نمایید.</span> : ''}
        </div>
        <button className={`btn`} type='submit'>
          {isLoading ? (
            <div className='lds-ring'>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          ) : (
            'ثبت'
          )}
        </button>
      </form>
      <Link className='register' to={'login'}>
        از اینجا وارد شوید.
      </Link>
      <ToastContainer position={'bottom-center'} delay={5000} />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  height: 100vh;
  background-color: #231e5b;
  .logo-container {
    background-color: #fccb09;
    padding: 10px;
    border-radius: 90px;
    display: block;
    margin-bottom: 30px;
    box-shadow: 0px 5px 10px 0 rgb(0 0 0 / 20%);
    overflow: hidden;
  }
  .logo {
    display: block;
    margin: 0 auto;
    width: 180px;
    @media (max-width: 376px) {
      width: 110px;
    }
  }
  form {
    padding: 30px;
    border-radius: 6px;
    background-color: #fff;
    width: 60%;
    box-shadow: 0px 5px 10px 0 rgb(0 0 0 / 20%);
  }
  .form-control {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    label {
      font-size: 15px;
      font-weight: 400;
      margin-bottom: 10px;
      display: inline-block;
      color: #231e5b;
    }
    .form-input {
      height: 30px;
      border-radius: 4px;
      border: 1px solid #e2e0e0;
      padding: 0 10px;
      color: #231e5b;
      font-size: 14px;
      box-shadow: none;
      .form-input:focus,
      .form-input:active {
        border-color: #231e5b;
      }
    }
    .error {
      display: inline-block;
      font-size: 13px;
      color: #cc0808;
      margin-top: 8px;
    }
    .has-error {
      border-color: #cc0808;
    }
  }
  .btn {
    border-radius: 4px;
    border: 0;
    box-shadow: 0;
    outline: none;
    background-color: #231e5b;
    color: #fff;
    font-size: 18px;
    font-weight: bold;
    padding: 8px 25px;
    font-family: 'dana';
    margin: 0 auto;
    margin-top: 20px;
    min-width: 120px;
    display: block;
    transition: opacity 280ms ease-in;
    &:hover {
      opacity: 0.9;
      transition: opacity 280ms ease-in;
    }
    @media (max-width: 376px) {
      font-size: 16px;
    }
  }
  .register {
    color: #fff;
    margin-top: 30px;
    font-size: 18px;
    text-decoration: none;
    @media (max-width: 376px) {
      font-size: 16px;
    }
  }
`;

export default ResetPassword;
